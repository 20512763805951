<template>
  <fw-layout mobile-ready :back-to-enable="false">
    <template #main-content>
      <fw-panel :title="$t('absences')" featured :loading="loading">
        <template v-if="!loading" #after-heading>
          <fw-heading size="h2" muted>{{ selectedYear }}</fw-heading>
        </template>
        <template #toolbar>
          <b-dropdown aria-role="list" position="is-bottom-left">
            <fw-button-dropdown
              slot="trigger"
              aria-role="listitem"
              type="xlight"
              :label="`${selectedYear || ''}`"
              class="font-medium text-gray-500"
            >
            </fw-button-dropdown>
            <b-dropdown-item
              :label="year - 1"
              aria-role="listitem"
              :class="`font-medium ${year - 1 == selectedYear ? 'text-primary' : 'text-gray-500'}`"
              @click.native="selectYear(year - 1)"
            >
              {{ year - 1 }}
            </b-dropdown-item>
            <b-dropdown-item
              :label="year"
              aria-role="listitem"
              :class="`font-medium ${year == selectedYear ? 'text-primary' : 'text-gray-500'}`"
              @click.native="selectYear(year)"
            >
              {{ year }}
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #default>
          <!-- <fw-panel
            v-if="false"
            :title="$t('unjustifiedAbsences')"
            boxed="xs"
            class="my-5"
            custom-class="bg-white flex flex-col gap-3"
            :counter="unjustifiedAbsences.length"
          >
            <fw-panel-info v-if="!unjustifiedAbsences || !unjustifiedAbsences.length" centered empty class="p-3">
              {{ $t('noUnjustifiedAbsences') }}
            </fw-panel-info>
            <div v-else>
              <RecordAbsence
                v-for="(unjustifiedAbsence, i) in unjustifiedAbsences"
                :key="i"
                :item="unjustifiedAbsence"
                @create-justification="createJustification"
              ></RecordAbsence>
            </div>
          </fw-panel> -->

          <fw-panel
            :title="$t('scheduledAbsences')"
            :boxed="!scheduledAbsences || !scheduledAbsences.length ? '' : 'xs'"
            class="my-5"
            :counter="scheduledAbsences.length"
          >
            <loading-placeholder v-if="loading" />
            <fw-panel-info v-else-if="!scheduledAbsences || !scheduledAbsences.length" empty clean>
              Não existem ausências agendadas para apresentar.
            </fw-panel-info>
            <div v-else>
              <RecordAbsence
                v-for="(item, j) in scheduledAbsences"
                :key="j"
                :item="item"
                @create-justification="createJustification"
              ></RecordAbsence>
            </div>
          </fw-panel>

          <fw-panel
            :title="$t('effectiveAbsences')"
            :boxed="!effectiveAbsences || !effectiveAbsences.length ? '' : 'xs'"
            class="my-5"
            :counter="effectiveAbsences.length"
          >
            <loading-placeholder v-if="loading" />
            <fw-panel-info v-else-if="!effectiveAbsences || !effectiveAbsences.length" clean empty>
              Não existem ausências efetivas para apresentar.
            </fw-panel-info>
            <div v-else>
              <RecordAbsence
                v-for="(item, j) in effectiveAbsences"
                :key="j"
                :item="item"
                @create-justification="createJustification"
              ></RecordAbsence>
            </div>
          </fw-panel>
        </template>
      </fw-panel>
    </template>

    <fw-panel-info label="Data (raw)" debug>
      <json-viewer :value="{ scheduledAbsences, effectiveAbsences, year, selectedYear }"></json-viewer>
    </fw-panel-info>
  </fw-layout>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import RecordAbsence from '@/components/records/RecordAbsence'

import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    LoadingPlaceholder,
    RecordAbsence
  },

  data() {
    return {
      loading: false,
      // unjustifiedAbsences: [],
      scheduledAbsences: [],
      effectiveAbsences: [],
      year: Dates.now().year(),
      selectedYear: Dates.now().year()
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    }
  },

  mounted() {
    this.getAbsences()
  },

  methods: {
    selectYear(year) {
      this.selectedYear = year
      this.getAbsences()
    },

    async getAbsences() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserAbsences({
          year: this.selectedYear
        })

        console.log('getAbsences :>> ', response)
        this.effectiveAbsences = response.effective
        this.scheduledAbsences = response.scheduled
      })

      this.loading = false
    },

    async createJustification(item) {
      console.log('justify this :>> ', item)
      try {
        let motive = null
        if (item.start_time || item.end_time) {
          motive = 'other'
        }
        const response = await this.api.addUserJustification({
          absences: [item],
          justification: {
            dates: [{ start_at: item.start_at, end_at: item.end_at }],
            start_time: item.start_time,
            end_time: item.end_time,
            motive: motive
          }
        })
        console.log('response :>> ', response)
        this.$router.push({
          name: 'absence-justification',
          params: { key: response.justification.key }
        })
      } catch (e) {
        console.error(e)
        this.$buefy.toast.open({
          message: 'Ocorreu um erro ao criar a justificação',
          type: 'is-danger',
          duration: 4000
        })
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "absences": "Ausências",
    "effectiveAbsences": "Efetivas",
    "noEffectiveAbsences": "Sem ausências efetivas",
    "scheduledAbsences": "Agendadas",
    "noScheduledAbsences": "Sem ausências agendadas",
    "unjustifiedAbsences": "Faltas por justificar",
    "noUnjustifiedAbsences": "Sem ausências por justificar"
  },
  "en": {
    "absences": "Absences",
    "effectiveAbsences": "Effective",
    "noEffectiveAbsences": "No effective absences",
    "scheduledAbsences": "Scheduled",
    "noScheduledAbsences": "No scheduled absences",
    "unjustifiedAbsences": "Unjustified absences",
    "noUnjustifiedAbsences": "No unjustified absences"
  }
}
</i18n>
