<template>
  <div
    class="relative rounded-lg px-3 py-2 md:px-3.5 md:py-3 hover:bg-gray-200 hover:bg-opacity-50 w-full border-b border-gray-100 flex gap-2"
  >
    <div class="w-4 relative pt-2 md:pt-2.5">
      <fw-dot :color="needsJustification ? 'danger' : 'light'" inline :ping="needsJustification" />
    </div>
    <div class="flex flex-1 md:items-center gap-1 md:gap-5 xl:gap-8 flex-col md:flex-row">
      <div class="flex md:items-center gap-1">
        <div class="flex items-center gap-2 font-medium relative flex-shrink-0">
          <fw-icon-calendar-event class="w-5 h-5 flex-shrink-0" />
          <span>{{ item.start_at }}</span>
        </div>
        <div v-if="item.start_at != item.end_at" class="flex items-center gap-1 font-medium flex-shrink-0">
          <fw-icon-arrow-right class="w-5 h-5 opacity-40 flex-shrink-0" />
          <span class="opacity-50">{{ item.end_at }}</span>
        </div>
      </div>

      <div v-if="item.start_time" class="text-sm pl-0.5 md:pl-0">
        <div class="flex items-center gap-1 font-medium">
          <fw-icon-clock-line class="w-4 h-4 flex-shrink-0" />
          <span>{{ item.start_time }}</span>
          <fw-icon-arrow-right v-if="item.start_time != item.end_time" class="w-4 h-4 flex-shrink-0" />
          <span v-if="item.start_time != item.end_time">{{ item.end_time }}</span>
        </div>
      </div>

      <div class="flex-1 flex gap-2 justify-between items-center">
        <div>
          <fw-tag v-if="item.start_time" type="xlight" size="sm">
            <div v-if="item.working_days_out">{{ item.working_days_out }}d</div>
            <div v-if="item.working_days_out && item.working_days_out" class="mx-1 opacity-50 text-sm">/</div>
            <div v-if="item.hours_out">{{ item.hours_out }}h</div>
          </fw-tag>
          <fw-tag v-else type="xlight" size="sm">
            {{ $t('businessHours') }}
          </fw-tag>
        </div>
        <div v-if="item.absence_type" class="text-sm text-gray-500">
          <fw-tag v-if="needsJustification" type="danger">Não justificada</fw-tag>
          <div v-else class="flex gap-1">
            <fw-icon-info class="w-4 h-4 flex-shrink-0 mt-0.5 mr-1" />
            <v-clamp autoresize :max-lines="1">{{ item.absence_type }}</v-clamp>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      expanded: false
    }
  },

  computed: {
    hasJustifications() {
      return this.item.justifications && this.item.justifications.length
    },

    needsJustification() {
      return this.item?.absence_code == 3412
    },

    canCreateJustification() {
      if (this.item.absence_code != 3412) return false
      if (this.item.justifications && this.item.justifications.length) {
        return Dates.buildCore(this.item.end_at).diff(Dates.buildCore(this.item.start_at), 'day') > 1
      } else {
        return true
      }
    }
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded
    },

    goToJustification() {
      this.$router.push({
        name: 'absence-justification',
        params: { key: this.item.justifications[0].key }
      })
    },

    createJustification() {
      const daysDiff = Dates.buildCore(this.item.end_at).diff(Dates.buildCore(this.item.start_at), 'day')

      if (this.item.justifications && this.item.justifications.length && daysDiff > 1) {
        this.$buefy.dialog.confirm({
          cancelText: 'Ver detalhes',
          confirmText: 'Criar',
          title: 'Nova justificação',
          message: 'Já existe uma justificação para esta ausência. Criar justificação na mesma?',
          onConfirm: async () => {
            this.$emit('create-justification', this.item)
          },
          onCancel: () => {
            return this.goToJustification()
          }
        })
      } else {
        this.$emit('create-justification', this.item)
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "workingDay": "dia útil",
    "workingDays": "dias úteis",
    "hour": "hora",
    "hours": "horas",
    "businessHours": "Horário útil"
  },
  "en": {
    "workingDay": "working day",
    "workingDays": "working days",
    "hour": "hour",
    "hours": "hours",
    "businessHours": "Business Hours"
  }
}
</i18n>
